import { graphql } from "gatsby"
import HomepageComponent from "../components/homepage"

export default HomepageComponent

export const query = graphql`
query($formatString: String!) {
  allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {date: {ne: null}}}) {
    nodes {
      childrenMdxPost {
        slug
      }
      excerpt
      timeToRead
      frontmatter {
        title
        date(formatString: $formatString)
        banner {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tags
        recipe {
          title
          ingredients {
            name
            percentage
          }
        }
      }
    }
  }
}
`